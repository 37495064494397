// Misc
export enum NetworkReducerTypes {
    LOAD_ALL_STATIC_CONTENT = "LOAD_ALL_STATIC_CONTENT",

    // Mines
    CREATE_MINE_RECORD = "CREATE_MINE_RECORD",
    CREATE_MINE_TYPE = "CREATE_MINE_TYPE",
    GET_MINE_RECORDS = "GET_MINE_RECORDS",
    GET_MINE_RECORD = "GET_MINE_RECORD",
    GET_SUBSCRIBED_MINES = "GET_SUBSCRIBED_MINES",
    GET_MINE_NAME_LIST = "GET_MINE_NAME_LIST",
    GET_MINE_BASIC_INFO_LIST = "GET_MINE_BASIC_INFO_LIST",
    GET_REGION_OPTIONS = "GET_REGION_OPTIONS",
    UPDATE_MINE_RECORD = "UPDATE_MINE_RECORD",
    CREATE_TSF = "CREATE_TSF",
    UPDATE_TSF = "UPDATE_TSF",
    GET_TSF = "GET_TSF",
    GET_MINE_DOCUMENTS = "GET_MINE_DOCUMENTS",
    ARCHIVE_MINE_DOCUMENTS = "ARCHIVE_MINE_DOCUMENTS",
    GET_MINE_VERIFIED_STATUS = "GET_MINE_VERIFIED_STATUS",
    SET_MINE_VERIFIED_STATUS = "SET_MINE_VERIFIED_STATUS",
    POLL_DOCUMENT_UPLOAD_STATUS = "POLL_DOCUMENT_UPLOAD_STATUS",

    // Mine Details
    REMOVE_MINE_TYPE = "REMOVE_MINE_TYPE",

    // Parties
    CREATE_PARTY = "CREATE_PARTY",
    UPDATE_PARTY = "UPDATE_PARTY",
    GET_PARTIES = "GET_PARTIES",
    GET_PARTY = "GET_PARTY",
    DELETE_PARTY = "DELETE_PARTY",
    GET_INSPECTORS = "GET_INSPECTORS",
    GET_PROJECT_LEADS = "GET_PROJECT_LEADS",
    ADD_PARTY_RELATIONSHIP = "ADD_PARTY_RELATIONSHIP",
    FETCH_PARTY_RELATIONSHIPS = "FETCH_PARTY_RELATIONSHIPS",
    REMOVE_PARTY_RELATIONSHIP = "REMOVE_PARTY_RELATIONSHIP",
    UPDATE_PARTY_RELATIONSHIP = "UPDATE_PARTY_RELATIONSHIP",
    ADD_DOCUMENT_TO_RELATIONSHIP = "ADD_DOCUMENT_TO_RELATIONSHIP",
    PARTY_ORGBOOK_ENTITY = "PARTY_ORGBOOK_ENTITY",
    MERGE_PARTIES = "MERGE_PARTIES",

    // OrgBook
    ORGBOOK_SEARCH = "ORGBOOK_SEARCH",
    ORGBOOK_CREDENTIAL = "ORGBOOK_CREDENTIAL",
    ORGBOOK_VERIFY = "ORGBOOK_VERIFY",

    // Compliance Orders
    GET_MINE_COMPLIANCE_INFO = "GET_MINE_COMPLIANCE_INFO",

    // MineSpace
    GET_MINESPACE_USER = "GET_MINESPACE_USER",
    CREATE_MINESPACE_USER = "CREATE_MINESPACE_USER",
    DELETE_MINESPACE_USER = "DELETE_MINESPACE_USER",
    GET_MINESPACE_USER_MINES = "GET_MINESPACE_USER_MINES",
    AUTHENTICATE_USER = "AUTHENTICATE_USER",
    UPDATE_MINESPACE_USER_MINES = "UPDATE_MINESPACE_USER_MINES",

    // MineSpace MCM contacts
    GET_MINISTRY_CONTACTS = "GET_MINISTRY_CONTACTS",
    UPDATE_MINISTRY_CONTACT = "UPDATE_MINISTRY_CONTACT",
    DELETE_MINISTRY_CONTACT = "DELETE_MINISTRY_CONTACT",

    // Permits
    CREATE_PERMIT = "CREATE_PERMIT",
    GET_PERMITS = "GET_PERMITS",
    UPDATE_PERMIT = "UPDATE_PERMIT",
    PATCH_PERMIT = "PATCH_PERMIT",
    CREATE_PERMIT_AMENDMENT = "CREATE_PERMIT_AMENDMENT",
    UPDATE_PERMIT_AMENDMENT = "UPDATE_PERMIT_AMENDMENT",
    GET_PERMIT_AMENDMENT = "GET_PERMIT_AMENDMENT",
    PERMIT_AMENDMENT_VC = "PERMIT_AMENDMENT_VC",
    UPDATE_PERMIT_AMENDMENT_DOCUMENT = "UPDATE_PERMIT_AMENDMENT_DOCUMENT",
    DELETE_PERMIT = "DELETE_PERMIT",
    DELETE_PERMIT_AMENDMENT = "DELETE_PERMIT_AMENDMENT",
    GET_PERMIT_CONDITIONS = "GET_PERMIT_CONDITIONS",
    CREATE_PERMIT_CONDITION = "CREATE_PERMIT_CONDITION",
    UPDATE_PERMIT_CONDITION = "UPDATE_PERMIT_CONDITION",
    DELETE_PERMIT_CONDITION = "DELETE_PERMIT_CONDITION",
    CREATE_PERMIT_CONDITION_CATEGORY = "CREATE_PERMIT_CONDITION_CATEGORY",
    UPDATE_PERMIT_CONDITION_CATEGORY = "UPDATE_PERMIT_CONDITION_CATEGORY",
    GET_PERMIT_CONDITION_CATEGORIES = "GET_PERMIT_CONDITION_CATEGORIES",
    DELETE_PERMIT_CONDITION_CATEGORY = "DELETE_PERMIT_CONDITION_CATEGORY",

    // Explosive Storage & Use Permits
    CREATE_EXPLOSIVES_PERMIT = "CREATE_EXPLOSIVES_PERMIT",
    GET_EXPLOSIVES_PERMITS = "GET_EXPLOSIVES_PERMITS",
    UPDATE_EXPLOSIVES_PERMIT = "UPDATE_EXPLOSIVES_PERMIT",
    DELETE_EXPLOSIVES_PERMIT = "DELETE_EXPLOSIVES_PERMIT",
    CREATE_EXPLOSIVES_PERMIT_AMENDMENT = "CREATE_EXPLOSIVES_PERMIT_AMENDMENT",
    UPDATE_EXPLOSIVES_PERMIT_AMENDMENT = "UPDATE_EXPLOSIVES_PERMIT_AMENDMENT",

    // Search
    GET_SEARCH_OPTIONS = "GET_SEARCH_OPTIONS",
    GET_SEARCH_RESULTS = "GET_SEARCH_RESULTS",
    GET_SEARCH_BAR_RESULTS = "GET_SEARCH_BAR_RESULTS",
    CLEAR_SEARCH_BAR_RESULTS = "CLEAR_SEARCH_BAR_RESULTS",
    CLEAR_ALL_SEARCH_RESULTS = "CLEAR_ALL_SEARCH_RESULTS",

    // Mine Subscriptions
    SUBSCRIBE = "SUBSCRIBE",
    UNSUBSCRIBE = "UNSUBSCRIBE",

    // Variances
    CREATE_MINE_VARIANCE = "CREATE_MINE_VARIANCE",
    GET_VARIANCES = "GET_VARIANCES",
    GET_VARIANCE = "GET_VARIANCE",
    ADD_DOCUMENT_TO_VARIANCE = "ADD_DOCUMENT_TO_VARIANCE",
    REMOVE_DOCUMENT_FROM_VARIANCE = "REMOVE_DOCUMENT_FROM_VARIANCE",
    UPDATE_MINE_VARIANCE = "UPDATE_MINE_VARIANCE",
    DELETE_VARIANCE = "DELETE_VARIANCE",

    // Projects
    UPDATE_PROJECT = "UPDATE_PROJECT",
    GET_PROJECTS = "GET_PROJECTS",
    GET_PROJECT = "GET_PROJECT",
    CREATE_MINE_PROJECT_SUMMARY = "CREATE_MINE_PROJECT_SUMMARY",
    GET_PROJECT_SUMMARIES = "GET_PROJECT_SUMMARIES",
    GET_PROJECT_SUMMARY = "GET_PROJECT_SUMMARY",
    GET_PROJECT_SUMMARY_MINISTRY_COMMENTS = "GET_PROJECT_SUMMARY_MINISTRY_COMMENTS",
    CREATE_PROJECT_SUMMARY_MINISTRY_COMMENTS = "CREATE_PROJECT_SUMMARY_MINISTRY_COMMENTS",
    REMOVE_DOCUMENT_FROM_PROJECT_SUMMARY = "REMOVE_DOCUMENT_FROM_PROJECT_SUMMARY",
    UPDATE_MINE_PROJECT_SUMMARY = "UPDATE_MINE_PROJECT_SUMMARY",
    DELETE_PROJECT_SUMMARY = "DELETE_PROJECT_SUMMARY",
    POST_NEW_DOCUMENT_VERSION = "POST_NEW_DOCUMENT_VERSION",
    INFORMATION_REQUIREMENTS_TABLE = "INFORMATION_REQUIREMENTS_TABLE",
    GET_REQUIREMENTS = "GET_REQUIREMENTS",
    GET_INFORMATION_REQUIREMENTS_TABLE = "GET_INFORMATION_REQUIREMENTS_TABLE",
    UPDATE_INFORMATION_REQUIREMENTS_TABLE = "UPDATE_INFORMATION_REQUIREMENTS_TABLE",
    REMOVE_DOCUMENT_FROM_INFORMATION_REQUIREMENTS_TABLE =
    "REMOVE_DOCUMENT_FROM_INFORMATION_REQUIREMENTS_TABLE",
    CREATE_MAJOR_MINES_APPLICATION = "CREATE_MAJOR_MINE_APLICATION",
    GET_MAJOR_MINES_APPLICATION = "GET_MAJOR_MINES_APPLICATION",
    UPDATE_MAJOR_MINES_APPLICATION = "UPDATE_MAJOR_MINE_APPLICATION",
    REMOVE_DOCUMENT_FROM_MAJOR_MINE_APPLICATION =
    "REMOVE_DOCUMENT_FROM_MAJOR_MINE_APPLICATION",
    CREATE_PROJECT_DECISION_PACKAGE = "CREATE_PROJECT_DECISION_PACKAGE",
    UPDATE_PROJECT_DECISION_PACKAGE = "UPDATE_PROJECT_DECISION_PACKAGE",
    REMOVE_DOCUMENT_FROM_PROJECT_DECISION_PACKAGE =
    "REMOVE_DOCUMENT_FROM_PROJECT_DECISION_PACKAGE",
    DOCUMENTS_COMPRESSION = "DOCUMENTS_COMPRESSION",
    POLL_DOCUMENTS_COMPRESSION_PROGRESS = "POLL_DOCUMENTS_COMPRESSION_PROGRESS",
    CREATE_PROJECT_LINKS = "CREATE_PROJECT_LINKS",
    DELETE_PROJECT_LINK = "DELETE_PROJECT_LINK",

    // Incidents
    CREATE_MINE_INCIDENT = "CREATE_MINE_INCIDENT",
    GET_INCIDENTS = "GET_INCIDENTS",
    GET_MINE_INCIDENTS = "GET_MINE_INCIDENTS",
    GET_MINE_INCIDENT = "GET_MINE_INCIDENT",
    UPDATE_MINE_INCIDENT = "UPDATE_MINE_INCIDENT",
    REMOVE_DOCUMENT_FROM_MINE_INCIDENT = "REMOVE_DOCUMENT_FROM_MINE_INCIDENT",
    DELETE_MINE_INCIDENT = "DELETE_MINE_INCIDENT",
    GET_MINE_INCIDENT_NOTES = "GET_MINE_INCIDENT_NOTES",
    CREATE_MINE_INCIDENT_NOTE = "CREATE_MINE_INCIDENT_NOTE",
    DELETE_MINE_INCIDENT_NOTE = "DELETE_MINE_INCIDENT_NOTE",

    // Work Information
    CREATE_MINE_WORK_INFORMATION = "CREATE_MINE_WORK_INFORMATION",
    GET_MINE_WORK_INFORMATIONS = "GET_MINE_WORK_INFORMATIONS",
    UPDATE_MINE_WORK_INFORMATION = "UPDATE_MINE_WORK_INFORMATION",
    DELETE_MINE_WORK_INFORMATION = "DELETE_MINE_WORK_INFORMATION",

    // Reports
    GET_REPORTS = "GET_REPORTS",
    UPDATE_MINE_REPORT = "UPDATE_MINE_REPORT",
    CREATE_MINE_REPORT = "CREATE_MINE_REPORT",
    DELETE_MINE_REPORT = "DELETE_MINE_REPORT",
    GET_MINE_REPORTS = "GET_MINE_REPORTS",
    GET_MINE_REPORT = "GET_MINE_REPORT",

    // Report Comments
    GET_MINE_REPORT_COMMENTS = "GET_MINE_REPORT_COMMENTS",
    UPDATE_MINE_REPORT_COMMENT = "UPDATE_MINE_REPORT_COMMENT",
    CREATE_MINE_REPORT_COMMENT = "CREATE_MINE_REPORT_COMMENT",
    DELETE_MINE_REPORT_COMMENT = "DELETE_MINE_REPORT_COMMENT",

    // Notices of Work
    GET_NOTICE_OF_WORK_APPLICATIONS = "GET_NOTICE_OF_WORK_APPLICATIONS",
    GET_NOTICE_OF_WORK_APPLICATION = "GET_NOTICE_OF_WORK_APPLICATION",
    GET_MINE_NOTICE_OF_WORK_APPLICATIONS = "GET_MINE_NOTICE_OF_WORK_APPLICATIONS",
    IMPORT_NOTICE_OF_WORK_APPLICATION = "IMPORT_NOTICE_OF_WORK_APPLICATION",
    CREATE_NOTICE_OF_WORK_APPLICATION_IMPORT_SUBMISSION_DOCUMENTS_JOB =
    "CREATE_NOTICE_OF_WORK_APPLICATION_IMPORT_SUBMISSION_DOCUMENTS_JOB",
    CREATE_NOTICE_OF_WORK_APPLICATION = "CREATE_NOTICE_OF_WORK_APPLICATION",
    CREATE_ADMIN_AMENDMENT_APPLICATION = "CREATE_ADMIN_AMENDMENT_APPLICATION",
    UPDATE_NOTICE_OF_WORK_APPLICATION = "UPDATE_NOTICE_OF_WORK_APPLICATION",
    GET_IMPORTED_NOTICE_OF_WORK_APPLICATION = "GET_IMPORTED_NOTICE_OF_WORK_APPLICATION",
    GET_ORIGINAL_NOTICE_OF_WORK_APPLICATION = "GET_ORIGINAL_NOTICE_OF_WORK_APPLICATION",
    CREATE_NOTICE_OF_WORK_APPLICATION_PROGRESS =
    "CREATE_NOTICE_OF_WORK_APPLICATION_PROGRESS",
    UPDATE_NOTICE_OF_WORK_APPLICATION_PROGRESS =
    "UPDATE_NOTICE_OF_WORK_APPLICATION_PROGRESS",
    CREATE_NOTICE_OF_WORK_APPLICATION_REVIEW = "CREATE_NOTICE_OF_WORK_APPLICATION_REVIEW",
    GET_NOTICE_OF_WORK_APPLICATION_REVIEW = "GET_NOTICE_OF_WORK_APPLICATION_REVIEW",
    UPDATE_NOTICE_OF_WORK_APPLICATION_REVIEW = "UPDATE_NOTICE_OF_WORK_APPLICATION_REVIEW",
    REMOVE_NOTICE_OF_WORK_APPLICATION_REVIEW = "REMOVE_NOTICE_OF_WORK_APPLICATION_REVIEW",
    REMOVE_NOTICE_OF_WORK_APPLICATION_DOCUMENT =
    "REMOVE_NOTICE_OF_WORK_APPLICATION_DOCUMENT",
    ADD_DOCUMENT_TO_NOTICE_OF_WORK = "ADD_DOCUMENT_TO_NOTICE_OF_WORK",
    SORT_NOTICE_OF_WORK_DOCUMENTS = "SORT_NOTICE_OF_WORK_DOCUMENTS",
    GET_IMPORT_NOTICE_OF_WORK_SUBMISSION_DOCUMENTS_JOB =
    "GET_IMPORT_NOTICE_OF_WORK_SUBMISSION_DOCUMENTS_JOB",
    UPDATE_NOTICE_OF_WORK_STATUS = "UPDATE_NOTICE_OF_WORK_STATUS",
    CREATE_NOTICE_OF_WORK_APPLICATION_DELAY = "CREATE_NOTICE_OF_WORK_APPLICATION_DELAY",
    UPDATE_NOTICE_OF_WORK_APPLICATION_DELAY = "UPDATE_NOTICE_OF_WORK_APPLICATION_DELAY",
    FETCH_NOTICE_OF_WORK_APPLICATION_DELAY = "FETCH_NOTICE_OF_WORK_APPLICATION_DELAY",

    // Securities
    GET_MINE_BONDS = "GET_MINE_BONDS",
    CREATE_BOND = "CREATE_BOND",
    UPDATE_BOND = "UPDATE_BOND",
    TRANSFER_BOND = "TRANSFER_BOND",
    GET_MINE_RECLAMATION_INVOICES = "GET_MINE_RECLAMATION_INVOICES",
    CREATE_RECLAMATION_INVOICE = "CREATE_RECLAMATION_INVOICE",
    UPDATE_RECLAMATION_INVOICE = "UPDATE_RECLAMATION_INVOICE",

    // Comments
    GET_MINE_COMMENTS = "GET_MINE_COMMENTS",
    CREATE_MINE_COMMENTS = "CREATE_MINE_COMMENTS",
    DELETE_MINE_COMMENT = "DELETE_MINE_COMMENT",

    // EPIC Information
    GET_MINE_EPIC_INFO = "GET_MINE_EPIC_INFO",

    // Notices of Departure
    CREATE_NOTICE_OF_DEPARTURE = "CREATE_NOTICE_OF_DEPARTURE",
    UPDATE_NOTICE_OF_DEPARTURE = "UPDATE_NOTICE_OF_DEPARTURE",
    GET_NOTICES_OF_DEPARTURE = "GET_NOTICES_OF_DEPARTURE",
    ADD_DOCUMENT_TO_NOTICE_OF_DEPARTURE = "ADD_DOCUMENT_TO_NOTICE_OF_DEPARTURE",
    GET_DETAILED_NOTICE_OF_DEPARTURE = "GET_DETAILED_NOTICE_OF_DEPARTURE",

    // Activities
    GET_ACTIVITIES = "GET_ACTIVITIES",

    // Dams
    CREATE_DAM = "CREATE_DAM",
    UPDATE_DAM = "UPDATE_DAM",
    GET_DAM = "GET_DAM",

    //Verifiable Credentials
    CREATE_VC_WALLET_CONNECTION_INVITATION = "CREATE_VC_WALLET_CONNECTION_INVITATION",
    FETCH_VC_WALLET_CONNECTION_INVITATIONS = "FETCH_VC_WALLET_CONNECTION_INVITATIONS",
    ISSUE_VC = "ISSUE_VC",
    DELETE_VC_WALLET_CONNECTION = "DELETE_VC_WALLET_CONNECTION",

    // CORE-specific
    // Documents
    GENERATE_NOTICE_OF_WORK_APPLICATION_DOCUMENT = "GENERATE_NOTICE_OF_WORK_APPLICATION_DOCUMENT",
    GET_NOTICE_OF_WORK_APPLICATION_DOCUMENT_CONTEXT_TEMPLATE = "GET_NOTICE_OF_WORK_APPLICATION_DOCUMENT_CONTEXT_TEMPLATE",
    EXPORT_NOTICE_OF_WORK_APPLICATION_DOCUMENT = "EXPORT_NOTICE_OF_WORK_APPLICATION_DOCUMENT",
    GENERATE_EXPLOSIVES_PERMIT_DOCUMENT = "GENERATE_EXPLOSIVES_PERMIT_DOCUMENT",
    GET_EXPLOSIVES_PERMIT_DOCUMENT_CONTEXT_TEMPLATE = "GET_EXPLOSIVES_PERMIT_DOCUMENT_CONTEXT_TEMPLATE",
    // Alerts
    CREATE_MINE_ALERTS = "CREATE_MINE_ALERTS",
    UPDATE_MINE_ALERT = "UPDATE_MINE_ALERT",
    DELETE_MINE_ALERT = "DELETE_MINE_ALERT",
    GET_MINE_ALERTS = "GET_MINE_ALERTS",
    GET_GLOBAL_MINE_ALERTS = "GET_GLOBAL_MINE_ALERTS",

    // MS-specific
    GET_USER_INFO = "GET_USER_INFO",
    GET_USER_MINE_INFO = "GET_USER_MINE_INFO"
}